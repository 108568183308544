import { useEffect, useState } from 'react'
import { useStore } from '../store/hooks'
import { identify } from './useKlaviyo'

export const useUser = () => {
  const auth = useStore(s => s.auth)
  const user = auth?.user

  const location = useStore(s => s.location)
  const selectedStore = useStore(s => s.store.selectedStore)

  const isCamelCard =
    selectedStore?.region === 'SA' ||
    selectedStore?.region === 'NT' ||
    user?.camelCardID ||
    user?.camelCardNumber ||
    location?.region === 'SA' ||
    location?.region === 'NT'

  const isLoggedIn = !!user

  const isHumpClubAvailable =
    user?.address?.state === 'VIC' ||
    user?.address?.state === 'TAS' ||
    user?.address?.state === 'QLD'

  const isGamesTestUser =
    user?.email?.endsWith('@pipelabs.com.au') ||
    user?.email?.endsWith('@thirstycammel.com.au') ||
    user?.email?.endsWith('@playspark.co')

  return { user, isLoggedIn, isCamelCard, isHumpClubAvailable, token: auth?.token, isGamesTestUser }
}
